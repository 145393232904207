import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { JncCryptoAddresses } from '../../tables/addresses/JncCryptoAddressModal'
import { getShortName, useJunoCommonFunctions } from '../../../helpers'
import JncPageTitle from '../global/JncPageTitle'
import { JncCryptoAddressView } from '../../tables/addresses/JncCryptoAddressView'
import { apiService } from '../../../../common/apiCallService'
import { BalanceCryptoSend } from '../../tables/balances/actions/crypto/BalanceCryptoSend'
import { JncDelete } from '../global/JncDelete'
import Icon from '../../icons/Icon'

const JncCryptoAddress = () => {
    const [JncCryptoAddress, setJncCryptoAddress] = useState(false)
    const [selectedCryptoAddress, setSelectedCryptoAddress] = useState({})
    const apiUrl = useSelector((state) => state.config.api_url)
    const { balancesCrypto } = useSelector((state) => state.juno)
    const clientData = useSelector((state) => state.juno.clientData)
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const [addressData, setAddressData] = useState([])
    const readOnly =
        clientData && clientData.readOnly ? clientData.readOnly : false

    const [addressActions, setAddressActions] = useState({
        add: false,
        edit: false,
        delete: false,
        viewCrypto: false,
    })

    const { getCryptoBalances } = useJunoCommonFunctions()

    const getCryptoAddress = () => {
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
                '/restapi/get-crypto-address',
            {
                clientId: clientData?.clientId,
            },
            async (data) => {
                if (data) {
                    setAddressData(data)
                }
            },
            (err) => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        if (clientData) {
            getCryptoAddress()
            getCryptoBalances()
        }
    }, [clientData])

    return (
        <>
            <JncPageTitle
                title="Address book"
                headerBtn="Add new"
                headerBtnAction={() =>
                    setAddressActions({ ...addressActions, add: true })
                }
            />
            <div className="jncCardsGrid">
                {addressData?.map((data) => {
                    const fromCryptoIcon = balancesCrypto.find(
                        (x) => x.currencyShortName === data?.cryptoId
                    )?.currencyIcon
                    return (
                        <button
                            onClick={() => {
                                setSelectedCryptoAddress(data)
                                setJncCryptoAddress(true)
                            }}
                            className="jncAccountCard jncCard"
                            disabled={readOnly}
                        >
                            <div className="jncAccountCard__grid">
                                <div className="jncAccountCard__shortName">
                                    <p>{getShortName(data.nickName)}</p>
                                    <img
                                        className="jncAccountCard__curr"
                                        src={`${imageBaseUrl}${fromCryptoIcon}`}
                                        alt="currencyIcon"
                                    />
                                </div>
                                <div className="jncAccountCard__content">
                                    <p className="jncAccountCard__title">
                                        {data.nickName}
                                    </p>
                                    <p className="jncAccountCard__account">
                                        {data.address}
                                    </p>
                                </div>
                            </div>
                        </button>
                    )
                })}
                {
                    <button
                        onClick={() =>
                            setAddressActions({ ...addressActions, add: true })
                        }
                        className={`jncAddItem min-768`}
                        disabled={readOnly}
                    >
                        <Icon id="addIcon" />
                        <p data-e2e="add-bank-account-btn">Add new address</p>
                    </button>
                }
            </div>

            <JncCryptoAddresses
                title={addressActions.edit ? 'Edit Address' : 'Add New Address'}
                onClose={() =>
                    setAddressActions({
                        ...addressActions,
                        add: false,
                        edit: false,
                    })
                }
                getCryptoAddress={getCryptoAddress}
                open={addressActions.edit || addressActions.add}
                addressData={selectedCryptoAddress}
                addressActions={addressActions}
                setAddressActions={setAddressActions}
            />

            <JncCryptoAddressView
                addressData={selectedCryptoAddress}
                onClose={() => setJncCryptoAddress(false)}
                setAddressActions={setAddressActions}
                addressActions={addressActions}
                open={JncCryptoAddress ? true : false}
            />

            <BalanceCryptoSend
                onClose={() =>
                    setAddressActions({ ...addressActions, viewCrypto: false })
                }
                open={addressActions.viewCrypto}
            />

            <JncDelete
                open={addressActions.delete}
                onClose={() => {
                    setAddressActions({ ...addressActions, delete: false })
                }}
                onDelete={() => {
                    setJncCryptoAddress(false)
                    setAddressActions({
                        ...addressActions,
                        delete: false,
                        edit: false,
                    })
                }}
                title="Delete Address"
                content="Want to delete this address?"
                DELETE_URL="/restapi/delete-crypto-address"
                request={{ addressId: selectedCryptoAddress._id }}
                dialogClassName={'jn-addressdetails-delete'}
                actionClassName={'jn-addressdetails-actionbox'}
                callback={getCryptoAddress}
            />
        </>
    )
}

export default JncCryptoAddress
