import React from 'react'
import JunoClientActionModal from '../table/JunoClientActionModal'
import JncButton from '../global/JncButton'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from 'react-router-dom';

export const JncPendingModal = (props) => {
    const { open, onClose, error, title, btnText="Track transaction", btnClick=()=>navigate('/client/transactions') } = props
    const navigate = useNavigate()

    const dialogContentComponent = () => {
        return (
            <>
                <div className="flex justify-center items-center flex-col validationModal">
                    <div className="rounded-full bg-[#FAFAFA] p-3">
                        <div className=" bg-[#F4F4F5] rounded-full p-3 ">
                            <div className="rounded-full flex items-center justify-center">
                                <AccessTimeIcon className='!w-10 !h-10 text-[#51525C] m-0' />
                            </div>
                        </div>
                    </div>
                    <p className="validationModal-title">{title}</p>
                    <p className="validationModal-subtitle" data-e2e-err="error-msg" data-e2e={error}>{error}</p>
                </div>
            </>
        )
    }

    const dialogActionsComponent = () => {
        return (
            <>
                <div className="jncModal__resp w-full" >
                    <JncButton
                        text= {btnText}
                        dataE2e="track-transaction-btn"
                        onClickCall={btnClick}
                        className={"w-full bg-[#D1D1D6]"}
                        isOutlinedStyle={true}
                    />
                </div>
            </>
        )
    }
    return (
        <JunoClientActionModal
            dialogClassName={"jn-addressdetails-pending"}
            onClose={onClose}
            states={{
                start: true,
                confirm: false,
            }}
            open={open}
            dialogContent={dialogContentComponent()}
            dialogActions={dialogActionsComponent()}
        />
    )
}
