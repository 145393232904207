import * as actionTypes from './actionTypes'

export function setJunoTableSearchValue(value) {
    return {
        type: actionTypes.SET_JUNO_TABLE_SEARCH_VALUE,
        value,
    }
}

export function setIndividualStateInfo(value) {
    return {
        type: actionTypes.SET_INDIVIDUAL_STATE_INFO,
        value,
    }
}

export function setClientData(value) {
    return {
        type: actionTypes.SET_CLIENT_DATA,
        value,
    }
}

export function setBalancesData(value) {
    return {
        type: actionTypes.SET_BALANCES_DATA,
        value,
    }
}

export function setCryptoBalancesData(value) {
    return {
        type: actionTypes.SET_CRYPTO_BALANCES_DATA,
        value,
    }
}

export function setAccountsData(value) {
    return {
        type: actionTypes.SET_ACCOUNTS_DATA,
        value,
    }
}

export function setTransactionsData(value) {
    return {
        type: actionTypes.SET_TRANSACTIONS_DATA,
        value,
    }
}

export function setStatementsData(value) {
    return {
        type: actionTypes.SET_STATEMENTS_DATA,
        value,
    }
}

export function setClientTransferFee(value) {
    return {
        type: actionTypes.SET_CLIENT_FEE_SETTING,
        value,
    }
}

export const setSuccessMessage = (message) => ({
    type: actionTypes.SET_SUCCESS_MESSAGE,
    payload: { message, severity: 'success' },
})

export const setErrorMessage = (message) => ({
    type: actionTypes.SET_ERROR_MESSAGE,
    payload: { message, severity: 'error' },
})

export const closeSnackbar = () => ({
    type: actionTypes.CLOSE_SNACKBAR,
})

export function displayCurrencyPage(value) {
    return {
        type: actionTypes.SET_CURRENCY_PAGE,
        value,
    }
}

export function setManagedCurrencies(value) {
    return {
        type: actionTypes.SET_MANAGED_CURRENCIES,
        value,
    }
}