import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import JunoClientActionModal from '../../../../components/table/JunoClientActionModal'
import FxConfirm from '../../../../components/form/FxConfirm'
import { apiService } from '../../../../../../common/apiCallService'
import {
    getCurrencySymbol,
    clearAllValuesInObj,
    validateAmount,
    toFixedTrunc,
    getClientTransactionUrl,
    removeCommas,
    useJunoCommonFunctions,
    validateCurrency,
} from '../../../../../helpers'
import _ from 'lodash'
import { setSuccessMessage } from '../../../../../../Redux/actions/junoAction'
import JncCurrenciesTypeahead from '../../../../components/global/JncCurrenciesTypeahead'
import JncButton from '../../../../components/global/JncButton'
import { JncSuccessModal } from '../../../../components/modals/JncSuccessModal'

export default function BalanceFiatFx(props) {
    const { onClose, open, row, selectCurrency } = props
    const { getBalances, getTransactions, getFeeForCurrency } =
        useJunoCommonFunctions()
    const clientData = useSelector((state) => state.juno.clientData)
    const apiUrl = useSelector((state) => state.config.api_url)
    const balancesData = useSelector((state) => state.juno.balancesCurrencies)
    const [isLoading, setIsLoading] = useState(false)
    const [isFailed, setIsFailed] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)
    const [amountError, setAmountError] = useState('')
    const [fromCurrencyError, setFromCurrencyError] = useState("")
    const [toCurrencyError, setToCurrencyError] = useState("")
    const [exchangeLoading, setExchangeLoading] = useState(false)
    const [fxRate, setfxRate] = useState(null)
    const [exchangeRate, setExchangeRate] = useState(null)
    const [failedErr, setFailedErr] = useState('')
    const [fxFee, setFxFee] = useState(null)
    const [successModal, setSuccessModal] = useState(false)

    const dispatch = useDispatch()

    const clientTransactionFee = useSelector(
        (state) => state.juno.clientTransactionFee
    )
    const exchangeUrl =
        (window.location.hostname === 'localhost' ? apiUrl : '') +
        '/restapi/get-fiat-exchange-rate'
    const [formData, setFormData] = useState({
        fromAmount: '',
        toAmount: '',
    })

    const [errorInputNames, setErrorsInputNames] = useState([])
    const [selectedCurrency, setSelectedCurrency] = useState({
        from: [],
        to: [],
    })

    useEffect(() => {
        if (row) {
            if (!row.transactionDetails) {
                setSelectedCurrency((prev) => ({
                    ...prev,
                    from: row.currencyShortName ? [row.currencyShortName] : [],
                }))
            } else {
                const fxData = row.transactionDetails[0]
                setSelectedCurrency({
                    from: [fxData.fromCurrency] || [],
                    to: [fxData.toCurrency] || [],
                })
            }
        }
        setExchangeRate
    }, [row, open])

    useEffect(() => {
        if (selectedCurrency.length > 0 && formData.amount) {
            setAmountError(
                validateAmount(
                    formData.amount,
                    0,
                    selectedCurrency,
                    balancesData
                )
            )
        }
    }, [selectedCurrency])

    function clearFormData() {
        setFormData(clearAllValuesInObj(formData))
        setSelectedCurrency({ from: [], to: [] })
        setAmountError('')
        setIsConfirm(false)
        setToCurrencyError("")
        setFromCurrencyError("")
    }

    const onCloseHandler = (isSuccess = false) => {
        if (!isLoading) {
            isSuccess && clearFormData()
            setIsConfirm(false)
            onClose()
            setErrorsInputNames([])
            setfxRate(null)
            setFxFee(null)
            setExchangeRate(null)
            setIsFailed(false)
        }
    }

    function reset() {
        setIsLoading(false)
        setIsFailed(false)
        clearFormData()
    }

    const declineConfirm = () => {
        setIsConfirm(false)
    }

    const handleToCurrency = (val) => {
        setSelectedCurrency((prev) => ({ ...prev, to: val }))
        setToCurrencyError(
            validateCurrency(
                val,
                balancesData,
            ),
        )
        if (
            validateCurrency(val, balancesData) ===
            ''
        ) {
            setErrorsInputNames((prev) =>
                prev.filter((x) => x !== 'toCurrency')
            )
        }
    }

    const handleFromCurrency = (val) => {
        setSelectedCurrency((prev) => ({ ...prev, from: val }))
        setAmountError('')
        setFromCurrencyError(
            validateCurrency(
                val,
                balancesData,
            ),
        )
        if (
            validateCurrency(val, balancesData) ===
            ''
        ) {
            setErrorsInputNames((prev) =>
                prev.filter((x) => x !== 'fromCurrency')
            )
        }
    }

    useEffect(() => {
        if (
            selectedCurrency.from.length > 0 &&
            selectedCurrency.to.length > 0 && !successModal
        ) {
            setFormData({
                fromAmount: '',
                toAmount: '',
            })
            handleExchangeRate()
        }
    }, [selectedCurrency])

    const handleExchangeRate = () => {
        const toCurrencyValue = selectedCurrency.to[0]
        const fromCurrencyValue =
            selectedCurrency.from[0] || row.currencyShortName
        try {
            setExchangeLoading(true)
            apiService(
                exchangeUrl,
                {
                    fromCurrency: fromCurrencyValue,
                    toCurrency: toCurrencyValue,
                },
                (data) => {
                    if (data) {
                        const clientFXFee = getFeeForCurrency(
                            fromCurrencyValue,
                            toCurrencyValue
                        )
                        const fixedRate = data?.fixedRate
                        const transactionFee = clientFXFee
                            ? (Number(fixedRate) * clientFXFee.percentFee) / 100
                            : 0
                        const fxRate = fixedRate - transactionFee
                        setFxFee(transactionFee)
                        setfxRate(fxRate)
                        setExchangeRate(fixedRate)
                        setExchangeLoading(false)
                    }
                },
                (err) => {
                    console.log(err)
                    setExchangeLoading(false)
                }
            )
        } catch (err) {
            console.log(err)
        }
    }

    const handleAmountChange = (e) => {
        if (fxRate) {
            let fromAmount, toAmount, amountError
            const name = e.target.name
            const value = e.target.value

            if (name === 'toAmount') {
                const fixedRate = 1 / fxRate
                fromAmount = fixedRate && removeCommas(value) * fixedRate
                fromAmount = toFixedTrunc(fromAmount, 2)
                toAmount = value
                amountError = validateAmount(
                    removeCommas(fromAmount),
                    0,
                    selectedCurrency.from,
                    balancesData
                )
            } else if (name === 'fromAmount') {
                toAmount = fxRate && removeCommas(value) * fxRate
                toAmount = toFixedTrunc(toAmount, 2)
                fromAmount = value
                amountError = validateAmount(
                    value,
                    0,
                    selectedCurrency.from,
                    balancesData
                )
            }
            setFormData({ fromAmount, toAmount })
            setAmountError(amountError)
        } else setErrorsInputNames((prev) => [...prev, 'toCurrency'])
    }

    function validateFields() {
        let updatedErrors = []
        if (validateCurrency(
            selectedCurrency.to[0],
            balancesData,
        )) {
            updatedErrors = [...updatedErrors, 'toCurrency']
            setToCurrencyError(
                validateCurrency(
                    selectedCurrency.to[0],
                    balancesData,
                )
            )
        }
        if (validateCurrency(
            selectedCurrency.from[0],
            balancesData,
        )) {
            updatedErrors = [...updatedErrors, 'fromCurrency']
            setFromCurrencyError(
                validateCurrency(
                    selectedCurrency.from[0],
                    balancesData,
                )
            )
        }

        if (
            validateAmount(
                removeCommas(formData.fromAmount),
                0,
                selectedCurrency.from,
                balancesData
            )
        ) {
            updatedErrors = [...updatedErrors, 'fromAmount']
            setAmountError(
                validateAmount(
                    removeCommas(formData.fromAmount),
                    0,
                    selectedCurrency.from,
                    balancesData
                )
            )
        }
        setErrorsInputNames(updatedErrors)
        return updatedErrors
    }

    function submit() {
        setIsLoading(true)
        setFormData({ ...formData, fromCurrency: selectedCurrency.from[0], toCurrency: selectedCurrency.to[0] })
        const balance = balancesData.find(
            (x) => x.currencyShortName === selectedCurrency.from[0]
        )
        const transactionEmail = localStorage.getItem('user_name')
        const deductedAmount =
            fxFee && (Number(removeCommas(formData.toAmount)) * fxFee) / fxRate
        apiService(
            getClientTransactionUrl(apiUrl),
            {
                currencyType: 'fiat',
                type: 'FX',
                client: clientData.clientId,
                transactionEmail,
                balance: {
                    balanceAmount: row.balanceAmount || balance.balanceAmount,
                },
                transactionFee: parseFloat(deductedAmount).toFixed(2),
                transactionDetails: {
                    ...formData,
                    fromCurrency: selectedCurrency.from[0],
                    toAmount:
                        formData.toAmount &&
                        parseFloat(removeCommas(formData.toAmount)).toFixed(2),
                    fromAmount:
                        formData.fromAmount &&
                        removeCommas(formData.fromAmount),
                    toCurrency: selectedCurrency.to[0],
                    fxrate: fxRate,
                    fxFee: fxFee,
                    exchangeRate: exchangeRate,
                },
            },
            async (data) => {
                if (data) {
                    setIsLoading(false)
                    setIsConfirm(false)
                    getBalances()
                    getTransactions()
                    dispatch(
                        setSuccessMessage('FX has been completed successfully')
                    )
                    onCloseHandler()
                    setSuccessModal(true)
                }
            },
            (err) => {
                setIsLoading(false)
                setIsConfirm(false)
                setIsFailed(true)
                setFailedErr(err)
                console.log(err)
            }
        )
    }

    const setIsConfirmHandler = () => {
        const error = validateFields()
        if (error.length === 0 && !exchangeLoading) {
            setIsConfirm(true)
        }
    }

    function DialogContentComponent() {
        return (
            <div>
                {isConfirm && (
                    <FxConfirm
                        declineConfirm={declineConfirm}
                        submit={submit}
                        fromAmount={formData.fromAmount}
                        fromCurrency={selectedCurrency.from}
                        toAmount={formData.toAmount}
                        toCurrency={selectedCurrency.to}
                        fixedRate={fxRate}
                        isLoading={isLoading}
                    />
                )}
                {row && !isConfirm && (
                    <div className="balance-fiat-fx">


                        <JncCurrenciesTypeahead
                            label="From Currency"
                            placeholder="Select From Currency"
                            selected={selectedCurrency.from}
                            onChange={handleFromCurrency}
                            hasError={!!fromCurrencyError}
                            error={fromCurrencyError}
                        />

                        <JncCurrenciesTypeahead
                            label="To Currency"
                            placeholder="Select To Currency"
                            dataE2e="to-currency"
                            fromCurrency={
                                row.currencyShortName ||
                                (selectedCurrency.from &&
                                    selectedCurrency.from[0])
                            }
                            selected={selectedCurrency.to}
                            onChange={handleToCurrency}
                            hasError={!!toCurrencyError}
                            error={toCurrencyError}
                        />
                        <div
                            className="jncModal__field mb"
                            style={{ position: 'relative' }}
                        >
                            <p className="jncModal__label">
                                Amount <span className="required">*</span>
                            </p>
                            <div className="jncModal_amount_input">
                                <div
                                    className={`jn-client-prefix-input ${amountError ? 'has-error' : ''
                                        }`}
                                >
                                    <span>
                                        {selectedCurrency.from[0]
                                            ? getCurrencySymbol(
                                                selectedCurrency.from[0]
                                            )
                                            : '?'}
                                    </span>
                                    <input
                                        data-e2e="from-amount"
                                        className="jncInput"
                                        name="fromAmount"
                                        onChange={(e) => handleAmountChange(e)}
                                        value={formData.fromAmount}
                                        type="text"
                                        placeholder="Enter Amount"
                                    />
                                </div>
                                <div className="theme-modal-field-input readOnly">
                                    {(selectedCurrency &&
                                        selectedCurrency.from[0]) ||
                                        '?'}
                                </div>
                            </div>
                        </div>
                        <div className="jncModal__field mb">
                            <p className="jncModal__label">Rate</p>
                            <div className="balance-fiat-fx-exchange">
                                1 {selectedCurrency.from[0]} =&nbsp;
                                {!exchangeLoading
                                    ? toFixedTrunc(fxRate, 4)
                                    : ''}
                                &nbsp;
                                {exchangeLoading && (
                                    <div className="data-loader"></div>
                                )}
                                &nbsp;
                                {selectedCurrency.to.length > 0
                                    ? selectedCurrency.to[0]
                                    : ''}
                            </div>
                        </div>
                        <div className="jncModal__field">
                            <p className="jncModal__label">Received amount</p>
                            <div className="jncModal_amount_input">
                                <div className="jn-client-prefix-input">
                                    <span>
                                        {selectedCurrency.to[0]
                                            ? getCurrencySymbol(
                                                selectedCurrency.to[0]
                                            )
                                            : '?'}
                                    </span>
                                    <input
                                        className="jncInput"
                                        name="toAmount"
                                        value={formData.toAmount}
                                        onChange={(e) => handleAmountChange(e)}
                                        type="text"
                                        placeholder="Enter Amount"
                                    />
                                </div>
                                <div className="theme-modal-field-input readOnly">
                                    {(selectedCurrency &&
                                        selectedCurrency.to[0]) ||
                                        '?'}
                                </div>
                            </div>
                        </div>
                        {amountError && (
                            <div
                                className="jncAlert alert alert-danger"
                                role="alert"
                                data-e2e={amountError}
                                data-e2e-error="alert"
                            >
                                {amountError}
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
    function DialogActionsComponent() {
        return (
            <div className="w-100">
                {isConfirm && (
                    <div className="jncModal__btns grid-on-mobile justify-end no-border">
                        <JncButton
                            disabled={isLoading}
                            text="Cancel"
                            isOutlinedStyle={true}
                            onClickCall={declineConfirm}
                        />
                        <JncButton
                            text="Exchange"
                            loading={isLoading}
                            onClickCall={submit}
                            dataE2e="confirm-btn"
                        />
                    </div>
                )}
                {!isConfirm && (
                    <div className="jncModal__btns justify-end">
                        <JncButton
                            isFullOnMobile={true}
                            loading={isLoading}
                            disabled={amountError || toCurrencyError || fromCurrencyError}
                            onClickCall={setIsConfirmHandler}
                            dataE2e="confirm"
                            text="Exchange"
                        />
                    </div>
                )}
            </div>
        )
    }

    useEffect(() => {
        if (open) {
            fetchBalances()
        }
    }, [open])


    const fetchBalances = async () => {
        await getBalances()
    }
    const handleSuccessModal = () => {
        setSuccessModal(false)
        clearFormData()
    };

    return (
        <>
            <JunoClientActionModal
                onClose={() => onCloseHandler(true)}
                titles={{ start: 'FX', confirm: 'FX Confirmation' }}
                backTo={{ account: declineConfirm }}
                states={{
                    start: !isConfirm,
                    confirm: isConfirm,
                }}
                open={open}
                isLoading={isLoading}
                isFailed={isFailed}
                failedText={failedErr}
                reset={reset}
                dialogContent={DialogContentComponent()}
                dialogActions={DialogActionsComponent()}
            />
            <JncSuccessModal
                open={successModal}
                onClose={handleSuccessModal}
                amount={formData}
                currency={formData}
                title="Successful!"
                subtitle="Has been exchanged successfully!"
                transactionType="Exchange"
            />
        </>
    )
}
