import React, { useEffect, useState } from 'react'
import JunoClientActionModal from '../../../../components/table/JunoClientActionModal'
import { useDispatch, useSelector } from 'react-redux'
import JncButton from '../../../../components/global/JncButton'
import { JncCustomSelect } from '../../../../components/global/JncCustomSelect'
import { MenuItem, Popover, Select } from '@mui/material'
import Icon from '../../../../icons/Icon'
import CopyButton from '../../../../components/global/CopyButton'
import { JncCryptoAddresses } from '../../../addresses/JncCryptoAddressModal'
import { apiService } from '../../../../../../common/apiCallService'
import { SendConfirm } from './confirmModal/SendConfirm'
import { getCurrencySymbol, handleCryptoAmountValidation, removeCommas, useJunoCommonFunctions, validateAmount, toFixedTrunc } from '../../../../../helpers'
import { JncSuccessModal } from '../../../../components/modals/JncSuccessModal'
import { JncFailedModal } from '../../../../components/modals/JncFailedModal'
import { JncPendingModal } from '../../../../components/modals/JncPendingModal'
import { erc20Tokens } from '../../../../constant'
import {
    setErrorMessage,
    setSuccessMessage,
} from '../../../../../../Redux/actions/junoAction'

export const BalanceCryptoSend = (props) => {
    const { onClose, open } = props
    const [popoverAnchors, setPopoverAnchors] = useState({})
    const { balancesCrypto, unfreezeCryptoCurrencies } = useSelector((state) => state.juno)
    const [formData, setFormData] = useState({
        fromAddress: '',
        toAddress: '',
        cryptoAmount: null,
        cryptoId: '',
        selectedName: ''
    })
    const [fee, setFee] = useState(0)
    const [transactionFee, setTransactionFee] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const { getCryptoBalances, getCryptoFee, getTransactions } = useJunoCommonFunctions()
    const [addressData, setAddressData] = useState([])
    const [addressFilteredData, setAddressFilteredData] = useState([])
    const [addressDropdown, setaddressDropdown] = useState(false);
    const apiUrl = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno.clientData)
    const [isConfirmModal, setIsConfirmModal] = useState(false)
    const [selectedCryptoAddress, setSelectedCryptoAddress] = useState({})
    const fromWalletOptions = unfreezeCryptoCurrencies;
    const [isLoading, setIsLoading] = useState(false)
    const [amountError, setAmountError] = useState('')
    const [addressActions, setAddressActions] = useState({
        add: false,
        edit: false,
    })
    const [successModal, setSuccessModal] = useState(false)
    const [processingModal, setProcessingModal] = useState({ open: false, msg: "" })
    const [failedModal, setFailedModal] = useState({ open: false, msg: "" })
    const dispatch = useDispatch()

    useEffect(() => {
        if (!successModal) {
            unfreezeCryptoCurrencies &&
                setFormData({ cryptoId: unfreezeCryptoCurrencies[0]?.currencyShortName })
        }
    }, [unfreezeCryptoCurrencies])

    const getCryptoAddress = () => {
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/get-crypto-address',
            {
                clientId: clientData?.clientId,
            },
            async (data) => {
                if (data) {
                    setAddressData(data)
                }
            },
            (err) => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        if (formData.cryptoId) {
            let updatedData;

            if (erc20Tokens.includes(formData.cryptoId)) {
                updatedData = addressData.filter(e =>
                    e.cryptoId === 'ETH' || e.cryptoId === formData.cryptoId
                );
            } else {
                updatedData = addressData.filter(e => e.cryptoId === formData.cryptoId);
            }

            setAddressFilteredData(updatedData);
        }
    }, [formData.cryptoId, addressData]);


    useEffect(() => {
        const fetchData = async () => {
            if (!formData.cryptoId || !open) return;
            const payload = {
                crypto: formData.cryptoId,
                profile: clientData.feeProfile,
                transactionType: 'Send',
                clientId: clientData.clientId
            };
            try {
                const cryptoFee = await getCryptoFee(payload);
                const newFee = !cryptoFee?.error ? cryptoFee?.data?.fee : 0
                setFee(!cryptoFee?.error ? cryptoFee?.data?.fee : 0);

                // If the crypto id changes, validate the balance for the updated crypto
                if (formData.cryptoAmount) {
                    const total = parseFloat(formData.cryptoAmount) + parseFloat(newFee);
                    const cryptoRangeError = validateAmount(total, 0, formData.cryptoId, balancesCrypto);
                    setAmountError(cryptoRangeError);
                }
            } catch (error) {
                console.error('Failed to fetch crypto fee:', error);
            }
        };

        fetchData();

    }, [formData.cryptoId, open]);


    useEffect(() => {
        if (balancesCrypto && open) {
            const walletAddress = balancesCrypto.find(crypto => crypto.currencyShortName === formData.cryptoId)?.walletAddress
            if (walletAddress) {
                setFormData({
                    ...formData,
                    fromAddress: walletAddress?.key,
                })
            } else {
                setFormData({
                    ...formData,
                    fromAddress: "",
                })
            }
        }
    }, [open, formData.cryptoId])

    const createCryptoTransaction = () => {
        setIsLoading(true)
        const blockchain = balancesCrypto.find(crypto => crypto.currencyShortName === formData.cryptoId)?.blockchain
        const fromAddress = fromWalletOptions.find(crypto => crypto.currencyShortName === formData.cryptoId)?.walletAddress?.key ?? null;
        const payload = {
            currencyType: 'crypto',
            type: 'Send',
            clientId: clientData?.clientId,
            transactionEmail: localStorage.getItem('user_name'),
            transactionFee: transactionFee && parseFloat(removeCommas(transactionFee)).toFixed(8),
            transactionDetails: {
                cryptoId: formData.cryptoId,
                blockchain,
                cryptoAmount: totalAmount,
                fromAddress: fromAddress,
                toAddress: formData.toAddress,
            },
        }
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/create-client-transaction-crypto',
            payload,
            async (data) => {
                handleClose()
                setSuccessModal(true)
                dispatch(setSuccessMessage('Send has been completed successfully'))
                getTransactions()
                getCryptoBalances()
            },
            (err) => {
                console.error(err)
                setIsLoading(false)
                if (!err.includes("processing")) {
                    setFailedModal({ open: true, msg: err })
                } else {
                    setProcessingModal({ open: true, msg: err })
                }
                dispatch(setErrorMessage(err))
            },
        )
    }

    const handleClose = (resetForm = true) => {
        onClose()
        setIsConfirmModal(false)
        setAmountError('')
        setIsLoading(false)
        if (resetForm) {
            setFormData({
                fromAddress: '',
                toAddress: '',
                cryptoAmount: null,
                cryptoId: unfreezeCryptoCurrencies[0]?.currencyShortName || null,
                selectedName: '',
            })
            setTotalAmount(0)
        }
        setTransactionFee(0)
        setPopoverAnchors({})
        setSelectedCryptoAddress({})
    }


    useEffect(() => {
        if (open) {
            getCryptoAddress()
        }
    }, [open, clientData])

    const handlePopoverOpen = (index, event) => {
        event.stopPropagation()
        setPopoverAnchors((prev) => ({
            ...prev,
            [index]: event.currentTarget,
        }))
    }

    const handlePopoverClose = (index) => {
        setPopoverAnchors((prev) => ({
            ...prev,
            [index]: null,
        }))
    }

    const handleButtonAction = (action, event) => {
        event.stopPropagation()
        if (action === 'edit') {
            setAddressActions({ add: false, edit: true })
        } else {
            setAddressActions({ add: true, edit: false })
        }
    }

    const handleCloseAddressModal = () => {
        setAddressActions({ add: false, edit: false })
    }




    useEffect(() => {
        if (formData && formData.cryptoAmount) {

            const cryptoAmount = parseFloat(removeCommas(formData.cryptoAmount)) || 0;
            const feeValue = parseFloat(fee) || 0;

            setTransactionFee(feeValue);

            const adjustedAmount = (cryptoAmount + feeValue).toFixed(8);

            const isValid = validateAmount(parseFloat(adjustedAmount), null, formData.cryptoId, balancesCrypto);

            if (isValid) {
                setTotalAmount(parseFloat(adjustedAmount));
            } else {
                setTotalAmount(parseFloat((cryptoAmount + feeValue).toFixed(8)));
            }
        }
    }, [formData, fee]);




    const handleAmountChange = (e) => {
        const total = parseFloat(e.target.value) + parseFloat(fee)
        const cryptoRangeError = validateAmount(
            total,
            0,
            formData.cryptoId,
            balancesCrypto
        )

        setAmountError(cryptoRangeError)
        setFormData({
            ...formData,
            cryptoAmount: e.target.value,
        })
    }

    const dialogContentComponent = () => {
        return (
            <>
                {
                    !isConfirmModal ?
                        <>
                            <div className="mb-[30px] flex justify-between items-center max-sm:flex-wrap">
                                <label className="text-base font-normal text-start">
                                    From Wallet:
                                </label>
                                <div className="max-sm:pt-3 flex items-center max-sm:w-full">
                                    <input
                                        type="text"
                                        onChange={handleAmountChange}
                                        data-e2e="from-wallet"
                                        placeholder='Enter amount'
                                        value={formData.cryptoAmount}
                                        className="bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[17px] pl-3"
                                    />
                                    <JncCustomSelect
                                        value={formData.cryptoId}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                cryptoId: e,
                                            })
                                        }
                                        options={fromWalletOptions}
                                        className="max-sm:w-[136px] !rounded-l-none"
                                    />
                                </div>
                            </div>
                            {amountError && (
                                <div
                                    className="jncAlert alert alert-danger mb-2"
                                    role="alert"
                                    data-e2e={amountError}
                                    data-e2e-error="alert"
                                >
                                    {amountError}
                                </div>
                            )}
                            <div className="mb-[30px] grid grid-cols-3 items-center max-sm:flex-wrap relative">
                                <label className="text-base font-normal col-span-1 max-sm:col-span-3 text-start">
                                    To:
                                </label>
                                <Select
                                    value={formData.toAddress || ''}
                                    open={addressDropdown}
                                    onOpen={() => setaddressDropdown(true)}
                                    onClose={() => setaddressDropdown(false)}
                                    className="bg-input max-sm:col-span-3 col-span-2 max-sm:mt-2 !rounded-3xl w-full"
                                    data-e2e="to-wallet-select"
                                    placeholder="Enter or select the address"
                                    renderValue={(selected) => {
                                        const selectedOption = addressFilteredData.find(
                                            (option) => option.address === selected
                                        )
                                        return selectedOption ? (
                                            <div className="flex items-center  truncate">
                                                {selectedOption.nickName} : {selectedOption.address}
                                            </div>
                                        ) : 'Select an address';
                                    }}
                                >
                                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                        {addressFilteredData.map((option, index) => {
                                            const isPopoverOpen = Boolean(
                                                popoverAnchors[index]
                                            )
                                            const popoverId = isPopoverOpen
                                                ? `simple-popover-${index}`
                                                : undefined

                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={option.address}
                                                    onClick={(event) => {
                                                        if (
                                                            !event.target.closest('button')
                                                        ) {
                                                            setFormData({
                                                                ...formData,
                                                                toAddress: option.address,
                                                                selectedName: option.nickName
                                                            })
                                                            setaddressDropdown(false)
                                                        }
                                                    }}
                                                >
                                                    <div className="flex justify-between items-center w-full">
                                                        <div>
                                                            <p className="text-base font-medium text-[#18181B] " dataE2e={option.nickName}>
                                                                {option.nickName}
                                                            </p>
                                                            <p className="text-[#70707B] text-sm font-normal">
                                                                {option.address}
                                                            </p>
                                                        </div>
                                                        <button
                                                            aria-describedby={popoverId}
                                                            type="button"
                                                            onClick={(event) =>
                                                                handlePopoverOpen(
                                                                    index,
                                                                    event
                                                                )
                                                            }
                                                        >
                                                            <Icon id="moreOptionIcon" />
                                                        </button>
                                                        <Popover
                                                            id={popoverId}
                                                            open={isPopoverOpen}
                                                            anchorEl={popoverAnchors[index]}
                                                            onClose={() =>
                                                                handlePopoverClose(index)
                                                            }
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                        >
                                                            <div className="p-2 w-[160px]">
                                                                <button
                                                                    className="flex items-center gap-2"
                                                                    onClick={(event) => {
                                                                        handleButtonAction(
                                                                            'edit',
                                                                            event
                                                                        )
                                                                        setSelectedCryptoAddress(option)
                                                                    }
                                                                    }
                                                                >
                                                                    <Icon id="edit" />
                                                                    <p className="text-[#51525C] text-sm font-medium">
                                                                        Edit
                                                                    </p>
                                                                </button>
                                                                <span
                                                                    onClick={(event) =>
                                                                        event.stopPropagation()
                                                                    }
                                                                >
                                                                    <CopyButton
                                                                        className="!ml-0 mt-2"
                                                                        copyValue={option.address}
                                                                        text={
                                                                            <p className="text-[#51525C] pl-2 text-sm font-medium">
                                                                                Copy
                                                                            </p>
                                                                        }
                                                                    />
                                                                </span>
                                                            </div>
                                                        </Popover>
                                                    </div>
                                                </MenuItem>
                                            )
                                        })}
                                    </div>
                                    <MenuItem
                                        key="add-new-address"
                                        value=""
                                        onClick={(event) => handleButtonAction(
                                            'add',
                                            event
                                        )}
                                        style={{
                                            position: 'sticky',
                                            bottom: 0,
                                            backgroundColor: 'white',
                                            zIndex: 1,
                                        }}
                                    >
                                        <div
                                            className="w-full text-center text-[#18181B] text-base font-medium"
                                            onClick={(event) =>
                                                handleButtonAction('add', event)
                                            }
                                        >
                                            + Add new address
                                        </div>
                                    </MenuItem>
                                </Select>
                            </div>
                            <div className="mb-[30px] flex justify-between items-center">
                                <label className="text-base font-normal text-start">
                                    Fee:
                                </label>
                                <span className="text-base font-semibold">
                                    {fee}
                                </span>
                            </div>
                            <hr className='mb-[30px]'></hr>
                            <div className=" flex justify-between items-center">
                                <label className="text-lg font-normal text-start">
                                    Total:
                                </label>
                                <span className='text-lg font-semibold'>{getCurrencySymbol(formData.cryptoId)} {totalAmount || 0} ({formData.cryptoId})</span>
                            </div>
                        </>
                        : <SendConfirm formData={formData} totalAmount={totalAmount} />}
            </>
        )
    }

    const handleConfirmationModal = () => {
        if (formData.toAddress && formData.cryptoId) {
            setIsConfirmModal(true)
        }
    }

    const dialogActionsComponent = () => {
        return (
            <div className="w-100">
                {
                    !isConfirmModal ?
                        <div className="jncModal__btns justify-end">
                            <JncButton
                                isFullOnMobile={true}
                                text="Continue"
                                disabled={amountError}
                                dataE2e="continue-btn"
                                onClickCall={handleConfirmationModal}
                            />
                        </div> :
                        <div className="jncModal__btns grid-on-mobile justify-end no-border">
                            <JncButton
                                isFullOnMobile={true}
                                text="Cancel"
                                disabled={isLoading}
                                onClickCall={() => handleClose()}
                            />
                            <JncButton
                                isFullOnMobile={true}
                                dataE2e="send-now-btn"
                                text="Send Now"
                                loading={isLoading}
                                onClickCall={() => createCryptoTransaction()}
                            />
                        </div>
                }
            </div>
        )
    }

    const handleSuccessModal = () => {
        setSuccessModal(false)
        setFormData({
            fromAddress: '',
            toAddress: '',
            cryptoAmount: null,
            cryptoId: unfreezeCryptoCurrencies[0]?.currencyShortName || null,
            selectedName: '',
        })
    }

    const handleFailedModal = () => {
        setFailedModal({ open: false, msg: '' })
        setFormData({
            fromAddress: '',
            toAddress: '',
            cryptoAmount: null,
            cryptoId: unfreezeCryptoCurrencies[0]?.currencyShortName || null,
            selectedName: '',
        })
        setIsConfirmModal(false)
        handleClose()
    }

    const handleProcessModal = () => {
        setProcessingModal({ open: false, msg: '' })
        setFormData({
            fromAddress: '',
            toAddress: '',
            cryptoAmount: null,
            cryptoId: unfreezeCryptoCurrencies[0]?.currencyShortName || null,
            selectedName: '',
        })
        setIsConfirmModal(false)
        getCryptoBalances()
        getTransactions()
    }
    return (
        <>
            <JunoClientActionModal
                onClose={handleClose}
                titles={{ start: 'Send Crypto', className: "jn-addressdetails-contentweight" }}
                states={{
                    start: true,
                    confirm: false,
                }}
                open={open}
                dialogContent={dialogContentComponent()}
                dialogActions={dialogActionsComponent()}
            />
            <JncCryptoAddresses
                title={addressActions.edit ? "Edit Address" : 'Add New Address'}
                onClose={handleCloseAddressModal}
                open={addressActions.edit || addressActions.add}
                addressData={selectedCryptoAddress}
                addressActions={addressActions}
                getCryptoAddress={getCryptoAddress}
            />
            <JncSuccessModal
                open={successModal}
                onClose={handleSuccessModal}
                amount={formData.cryptoAmount}
                currency={formData.cryptoId}
                title="Send Confirmed!"
                subtitle="Has been deposited into your Juno Money wallet."
            />
            <JncFailedModal
                open={failedModal.open}
                onClose={handleFailedModal}
                error={"Please contact Support"}
                title="Send Failed!"
            />
            <JncPendingModal
                open={processingModal.open}
                title="Pending..."
                onClose={handleProcessModal}
                error={processingModal.msg}
            />
        </>
    )
}
