import React, { useEffect, useState } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    Drawer,
    Button,
    Box,
    Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import JncDialogTitle from './JncDialogTitle'
import JncButton from '../global/JncButton'
import { defaultCurrenciesToDisplay, useJunoCommonFunctions } from '../../../helpers'

function ManageCurrenciesDrawer({ onClose, open, balancesData }) {
    const image_base_url = useSelector((state) => state.config.image_base_url)
    const { clientData, managedCurrencies } = useSelector((state) => state.juno)
    const { updateManagedCurrencies } = useJunoCommonFunctions()

    const [selectedCurrencies, setSelectedCurrencies] = useState(
        defaultCurrenciesToDisplay
    )
    const [alertMsg, setAlertMsg] = useState('')
    const [isDisable, setIsDisable] = useState(false)

    const currencies = balancesData.map((x) => {
        return {
            currencyShortName: x.currencyShortName,
            currencyIcon: x.currencyIcon,
            isActive: selectedCurrencies?.includes(x.currencyShortName),
        }
    })

    const selectCurrency = (currency) => {
        setSelectedCurrencies((prev) => {
            const newCurrencies = prev.includes(currency)
                ? prev.filter((x) => x !== currency)
                : [...prev, currency]

            const isSingleCurrency = newCurrencies.length === 0

            setAlertMsg(
                isSingleCurrency ? 'At least one currency must be selected' : ''
            )
            setIsDisable(isSingleCurrency ? true : false)

            return newCurrencies
        })
    }

    function checkIsSelected(currency) {
        return selectedCurrencies?.includes(currency)
    }

    function saveChanges() {
        if (clientData?.clientId) {
            updateManagedCurrencies(clientData?.clientId, selectedCurrencies)
        }
        handleClose()
    }

    useEffect(() => {
        if (managedCurrencies?.length) {
            setSelectedCurrencies(managedCurrencies)
        }
    }, [managedCurrencies])

    const handleClose = () => {
        setSelectedCurrencies(managedCurrencies)
        onClose()
    }

    return (
        <div>
            <Drawer
                anchor="bottom"
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiDrawer-paper': {
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px',
                    },
                  }}
            >
                <JncDialogTitle title="Manage currencies" onClose={handleClose} />
                <div className="jncModal__body">
                    <p className="jncModal__text">
                        Select or deselect one or multiple balance below you
                        would like to see within dashboard.
                    </p>
                    <div className="jncManageModal">
                        {currencies.map((x) => (
                            <button
                                onClick={() =>
                                    selectCurrency(x.currencyShortName)
                                }
                                key={x.currencyShortName}
                                className={`jncManageModal__curr ${
                                    x.isActive ||
                                    checkIsSelected(x.currencyShortName)
                                        ? 'is-active'
                                        : ''
                                }`}
                            >
                                {(x.isActive ||
                                    checkIsSelected(x.currencyShortName)) && (
                                        <img className = "absolute top-0 right-0 !w-5 !h-5" src={`${image_base_url}/common/currency-tick.svg`}
                                    />
                                )}
                                <img
                                    src={`${image_base_url}${x.currencyIcon}`}
                                />
                                <p data-e2e={x.currencyShortName}>
                                    {x.currencyShortName}
                                </p>
                            </button>
                        ))}
                    </div>
                </div>
                {alertMsg && (
                    <div className={`alert alert-danger`} role="alert">
                        {alertMsg}
                    </div>
                )}
                <div
                    data-e2e="save-btn"
                    className="jncModal__btns justify-end m-3"
                >
                    <JncButton
                        dataE2e="apply-icon"
                        onClickCall={saveChanges}
                        disabled={isDisable}
                        text="Apply"
                        isFullOnMobile={true}
                    />
                </div>
            </Drawer>
        </div>
    )
}

export default ManageCurrenciesDrawer
