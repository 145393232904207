import React from 'react'
import JunoClientActionModal from '../../components/table/JunoClientActionModal'
import Icon from '../../icons/Icon'
import JncTransactionFilters from './JncTransactionFilters'
import JncButton from '../../components/global/JncButton'
import { useJunoCommonFunctions } from '../../../helpers'
import { useSelector } from 'react-redux'

export default function TransactionMobileFilters({
    onClose,
    open,
    filter,
    setFilter,
}) {
    const { useWindowWidth } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const handleClearFilters = () => {
        const defaultFilter = {
            currency: [],
            currencyType: '',
            type: '',
            from: '',
            to: '',
            status: '',
            page: 1,
            pageSize: window.innerWidth > 991 ? 10 : 5,
        }
        setFilter(defaultFilter)
    }

    function DialogContentComponent() {
        return (
            <div>
                <JncTransactionFilters filter={filter} setFilter={setFilter} />
            </div>
        )
    }

    function DialogActionsComponent() {
        return (
            <div className="w-100">
                <div className="jncModal__btns grid-on-mobile justify-between no-border">
                    <button
                        className={`jncActionBtn hover:!bg-gray-100 jncHint jncBalanceCard__actions-item min-991`}
                        onClick={handleClearFilters}
                    >
                        <img
                            alt="resetIcon"
                            data-e2e="clear-all"
                            src={`${imageBaseUrl}/webUI/resetIcon.svg`}
                            className="w-5 h-5"
                        />
                    </button>
                    <JncButton
                        text="Save"
                        onClickCall={onClose}
                        isFullOnMobile={true}
                    />
                </div>
            </div>
        )
    }

    return (
        <JunoClientActionModal
            onClose={onClose}
            title="Filter"
            open={open}
            dialogContent={DialogContentComponent()}
            dialogActions={DialogActionsComponent()}
            // Fullscreen for mobile only
            fullScreen={windowWidth <= 767 && true}
        ></JunoClientActionModal>
    )
}
