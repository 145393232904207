import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { apiService } from '../../../../common/apiCallService'
import {
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    Select,
} from '@mui/material'
import JncDialogTitle from '../../components/modals/JncDialogTitle'
import JncButton from '../../components/global/JncButton'
import { useJunoCommonFunctions } from '../../../helpers'

const JncAdditionalUserModal = ({
    onClose,
    open,
    userEditData,
    handleUpdateUser,
    isEdit,
    getClientUser,
}) => {
    const clientData = useSelector((state) => state.juno.clientData)
    const api_url = useSelector((state) => state.config.api_url)
    const [isLoading, setIsLoading] = useState(false)
    const [reqError, setReqError] = useState('')
    const [data, setData] = useState({
        clientEmail: '',
        password: '',
        confirmPassword: '',
        readOnly: false,
    })
    const readOnlyOptions = [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
    ]
    const { useWindowWidth } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()

    const handleChange = (e) => {

        setData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }))
    }

    const handleEmail = () => {
        const regex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        if (!regex.test(data.clientEmail)) {
            setReqError('Invalid email!')
        } else {
            setReqError('')
        }
    }

    const handleFocus = () => {
        setReqError('')
    }

    const handleConfirmPassword = () => {
        if (data.password !== data.confirmPassword) {
            setReqError('Passwords do not match!')
        } else {
            setReqError('')
        }
    }

    useEffect(() => {
        if (isEdit) {
            setData((prev) => ({
                ...prev,
                readOnly: userEditData.readOnly,
                clientEmail: userEditData.clientEmail,
            }))
        }
    }, [userEditData, isEdit])

   const addClientUser = async () => {

    if (!data.clientEmail) {
        setReqError('Email is required!');
        return;
    }
    const emailRegex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailRegex.test(data.clientEmail)) {
        setReqError('Invalid email format!');
        return;
    }
    if (!data.password) {
        setReqError('Password is required!');
        return;
    }
    if (!data.confirmPassword) {
        setReqError('Confirm password is required!');
        return;
    }
    if (data.password !== data.confirmPassword) {
        setReqError('Passwords do not match!');
        return;
    }

    if (clientData) {
        setIsLoading(true);
        const payload = {
            clientId: clientData.clientId,
            clientEmail: data.clientEmail,
            password: data.password,
            readOnly: data.readOnly,
            parentClient: clientData._id,
        };
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/create-client-associated-user',
            {
                ...payload,
            },
            async (data) => {
                if (data) {
                    setIsLoading(false);
                    getClientUser();
                    handleClose();
                }
            },
            (err) => {
                console.log(err);
                setReqError(err);
                setIsLoading(false);
            }
        );
    }
};


    const handleClose = () => {
        onClose()
        setReqError('')
        setData({ clientEmail: '', password: '', confirmPassword: '', readOnly: false })
    }

    return (
        <Dialog
            className="jncModal jncStatementsModal"
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            onClose={handleClose}
            open={open}
            fullScreen={windowWidth < 768}
        >
            <JncDialogTitle
                onClose={handleClose}
                title={isEdit ? 'Edit additional users' : 'Add additional users'}
            />
            <DialogContent dividers>
                <div className="jncModal__body">
                    <div className="jncModal__field mb">
                        <p className="jncModal__label">Client User Email</p>
                        <input
                            data-e2e="enter-email"
                            className="jncInput"
                            type="text"
                            placeholder="Enter user email"
                            value={data.clientEmail}
                            name="clientEmail"
                            required
                            onChange={handleChange}
                            onBlur={handleEmail}
                            onFocus={handleFocus}
                        />
                    </div>
                    <div className="jncModal__field mb">
                        <p className="jncModal__label">Password</p>
                        <input
                            className="jncInput"
                            data-e2e="enter-password"
                            type="password"
                            autoComplete="new-password"
                            placeholder="Create Password"
                            name="password"
                            required
                            onChange={handleChange}

                            
                        />
                    </div>
                    <div className="jncModal__field mb">
                        <p className="jncModal__label">Confirm Password</p>
                        <input
                            className="jncInput"
                            data-e2e="confirm-password"
                            type="password"
                            autoComplete="new-password"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            onChange={handleChange}
                            required
                            onBlur={handleConfirmPassword}
                            onFocus={handleFocus}
                        />
                    </div>
                    <div className="jncModal__field mb">
                        <p className="jncModal__label">ReadOnly</p>
                        <Select
                            data-e2e={data.readOnly}
                            className="jncSelect"
                            name="readOnly"
                            value={data.readOnly}
                            placeholder="read only"
                            onChange={handleChange}
                            required
                        >
                            {readOnlyOptions &&
                                readOnlyOptions.map((opt) => (
                                    <MenuItem
                                        key={opt.label}
                                        value={opt.value}
                                        data-e2e={opt.label}
                                    >
                                        {opt.label}
                                    </MenuItem>
                                ))}
                        </Select>
                    </div>
                    {reqError && (
                        <div
                            className="jncAlert alert alert-danger"
                            role="alert"
                            data-e2e={reqError}
                        >
                            {reqError}
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <div className="w-100 jncModal__btns justify-end grid-on-mobile">
                    <JncButton
                        dataE2e="cancel-btn"
                        onClickCall={handleClose}
                        text="Cancel"
                        isOutlinedStyle={true}
                    />
                    <JncButton
                        loading={isLoading}
                        onClickCall={() =>
                            isEdit
                                ? handleUpdateUser(data, 'update')
                                : addClientUser()
                        }
                        dataE2e={isEdit ? 'Update User' : 'Add User'}
                        text={isEdit ? 'Save' : 'Add User'}
                    />
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default JncAdditionalUserModal
