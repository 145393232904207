export const trim = (value) => {
    if (value && typeof value === 'string') {
        return value.trim()
    }
    return value
}


export const isBlank = (value) => {
    return !value || (typeof value == 'string' && trim(value) == '')
}

export const toNumber = (value) => {
    let result = Number(value)
    if (isNaN(result)) return 0
    return result
}
